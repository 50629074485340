import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, Classes, Events } from '../global/constants';

gsap.registerPlugin(ScrollTrigger);


// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			const headerTimeline = gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});


	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	// ---------- Simple Text Image Section Animation ----------

	$('.simple-text-image-section').each(function() {
		const imageColumn = $(this).find('.image-column .column-image');

		Animations.global.push(() => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top 80%',
					once: true
				}
			});

			timeline.fromTo(imageColumn,
				{ y: '100%', opacity: 0 },
				{ duration: 1, y: '0%', opacity: 1, ease: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)' }
			);
		});
	});

	// ---------- Wine Home Section / News Section Animation / Other collections Section ----------

	$('.wine-home-section, .news-section, .other-collections-section').each(function() {
		const imageColumns = $(this).find('.content-column.image-column');

		Animations.global.push(() => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top 80%',
					once: true
				}
			});

			imageColumns.each((index, element) => {
				timeline.fromTo(element,
					{ y: '50%', opacity: 0 },
					{
						duration: 0.5,
						y: '0%',
						opacity: 1,
						ease: 'none',
						delay: 0
					}
				);
			});
		});
	});

	// ---------- Our visits ----------

	$('.our-visits-section').each(function() {
		const section = $(this);
		const textColumn = section.find('.content-column.text-column');
		const fadeInText = textColumn.find('.content > *');
		const image = section.find('.content-column.image-column .column-image img');

		Animations.mobile.min.push(() => {
			gsap.fromTo(fadeInText,
				{ opacity: 0, y: 50 },
				{
					opacity: 1,
					y: 0,
					duration: 1,
					delay: 0,
					scrollTrigger: {
						trigger: section[0],
						start: 'top 80%',
						once: true,
						onEnter: () => {
							textColumn.addClass('fade-in-text active');
							image.addClass('zoom-out');
						}
					}
				}
			);
		});
	});

	// ---------- Wine content Section ----------

	$('.wine-content-section').each(function() {
		const title = $(this).find('h2');


		gsap.from(title, {
			y: 50,
			opacity: 0,
			duration: 0.8,
			ease: 'none',
			scrollTrigger: {
				trigger: title,
				start: 'top 80%',
			}
		});
	});

	// ---------- Collection Section (single wine) ----------

	$('.collection-section').each(function() {
		const imageColumns = $(this).find('.image-column');

		imageColumns.each(function(index, element) {
			gsap.from(element, {
				x: index % 2 === 0 ? '-100%' : '100%',
				opacity: 0,
				duration: 2,
				ease: 'power3.out',
				scrollTrigger: {
					trigger: element,
					start: 'top 80%',
				}
			});
		});
	});

	// ---------- Wine Section  ----------

	$('.wine-section .collection-column').each(function() {
		const column = $(this);
		const images = column.find('.column-image');
		const fromDirection = column.hasClass('right-image') ? '100%' : '-100%';

		images.each(function(index, element) {
			const delay = column.hasClass('right-image') ? index * 0.2 : (images.length - 1 - index) * 0.2;

			gsap.from(element, {
				x: fromDirection,
				opacity: 0,
				duration: 1,
				delay: delay,
				ease: 'power3.out',
				scrollTrigger: {
					trigger: column,
					start: 'top 80%',
				}
			});
		});
	});

	// ---------- Footer Animation (Icon Star) ----------

	$('.icon-star').each(function() {
		const iconStar = $(this);

		gsap.fromTo(
			iconStar,
			{
				'--before-width': '10%',
				'--after-width': '10%'
			},
			{
				'--before-width': '50%',
				'--after-width': '50%',
				duration: 1,
				ease: 'power3.out',
				scrollTrigger: {
					trigger: iconStar,
					start: 'top 100% ',
					once: true
				}
			}
		);
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
