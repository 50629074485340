import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Events, Animations } from '../global/constants';

// ---------- Init Base Animation ----------

export const initBaseAnimations = () => {

	// ---------- Menu Animation ----------

	Animations.menu.min.push(() => {
		const columns = gsap.utils.toArray('.menu-content .col-informations, .menu-content .col-menu, .menu-content .col-image');

		gsap.set(columns, { opacity: 0, y: 20 });

		$('.menu-toggle').on('click', function() {
			gsap.set(columns, { opacity: 0, y: 20 });

			const menuTimeline = gsap.timeline();

			columns.forEach((column, index) => {
				menuTimeline.to(column, {
					opacity: 1,
					y: 0,
					duration: 0.6,
					ease: 'none',
					delay: index * 0.2
				}, 0);
			});
		});
	});

	// ---------- Hero header ----------

	const headerSection = $('.header-section .section-wrapper');
	const headerContent = $('.header-section .section-content .content');

	const introSection = $('.introduction-section .section-columns');
	const introText = $('.introduction-section .text-column .content');

	if(headerSection.length) {
		gsap.set(headerContent, { opacity: 0, y: 100 });
	}

	if(introSection.length) {
		gsap.set(introSection, { opacity: 0, y: '100vh' });
		gsap.set(introText, { opacity: 0, y: 100 });
	}

	if(headerContent.length) {
		gsap.to(headerContent, {
			duration: 1,
			opacity: 1,
			y: 0,
			ease: "power3.out",
			onComplete: () => {
				gsap.to(headerSection, {
					duration: 1,
					height: "75vh",
					onComplete: () => {
						if(introSection.length) {
							gsap.to(introSection, {
								duration: 1,
								opacity: 1,
								y: 0,
								delay: 0,
								ease: "power3.out",
								onComplete: () => {
									gsap.to(introText, {
										duration: 0.5,
										opacity: 1,
										y: 0,
										ease: "power3.out"
									});
								}
							});
						}
					}
				});
			}
		});
	}
	// ---------- Single Wine Hero header ----------

	const heroImage = $('.single-wine-hero-section .packshot-column .column-image');
	const drawLine = $('.single-wine-hero-section .section-content .draw-line');

	if(heroImage.length) {
		gsap.fromTo(heroImage,
			{ y: '75vh' },
			{
				y: '8rem',
				duration: 1,
				ease: 'none',
				onComplete: () => {
					gsap.to(drawLine, {
						height: '20rem',
						duration: 1,
						ease: 'none',
					});
				}
			}
		);
	}
};

$Elements.document.on(Events.page.enter, () => initBaseAnimations());
