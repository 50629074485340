import $ from 'jquery';
import StoreLocator from 'store-locator';

import { $Elements, Events, MapTiles } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Simple Map ----------

	$('.simple-map').each(function() {

		let marker = this.dataset.marker;
		marker = (typeof marker === 'string') ? JSON.parse(marker) : marker;

		const map = new StoreLocator({
			stores: marker,
			selectors: {
				map: this.attributes.id.value,
			},
			map: {
				tiles: MapTiles,
				options: {
					maxZoom: 15,
				},
				markers: {
					icon: feature => L.icon({
						iconUrl: feature.properties.icon,
						iconSize: [50, 50],
						iconAnchor: [44, 20],
						popupAnchor: [0, -120],
					}),
				}
			}
		});
	});

});
