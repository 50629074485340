import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { deviceType } from 'detect-it';

import { $Elements, Animations, Classes, Events } from './constants';
import { fixMobileHover, getCookiesAllowed, initGTM, initLightboxes, isDisclaimerAccepted, setCookiesAllowed, setDisclaimerAccepted, smoothScrollTo, sortAnimations, wait } from './helpers';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Touch Screen ----------

if(deviceType === 'touchOnly') {
	$Elements.html.addClass('touchscreen');
}

// ---------- Fix VH ----------

$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
$Elements.window.trigger('resize');

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, async() => {

	// ---------- Fix Mobile Hover ----------

	fixMobileHover();

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.PageWrapper.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.PageWrapper.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			smoothScrollTo(target);
		}
	});

	$Elements.PageWrapper.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- Anchor ----------

	const hash = window.location.hash;

	if(hash) {
		await new Promise(resolve => setTimeout(resolve, 1000));

		const target = $(hash);
		if(target.length) {
			const windowHeight = $(window).height();
			const headerHeight = $('header').outerHeight();
			const targetOffset = target.offset().top;

			const targetPosition = targetOffset - (windowHeight / 2) + (target.outerHeight() / 2) - headerHeight;

			$('html, body').animate({
				scrollTop: targetPosition
			}, 1000);
		}
	}

	// ---------- GLightbox ----------

	initLightboxes();

	// ---------- Cookies ----------

	initGTM();

	if(!getCookiesAllowed()) {

		const cookies = document.querySelector('#cookies-allowed');

		if(cookies) {

			cookies.classList.remove(Classes.hidden);
			await wait(100);
			cookies.classList.add(Classes.show);

			$(cookies).on('click', '[data-bs-dismiss="alert"]', function() {
				setCookiesAllowed(this.dataset.allowed);
			});
		}
	}

	// ---------- Has section links ----------

	const linksSection = document.querySelector('.links-section');

	if(linksSection) {
		document.body.classList.add('has-links-section');
	}

});

// ---------- Disclaimer ----------

if(!isDisclaimerAccepted()) {

	const disclaimer = document.querySelector('#disclaimer');

	if(disclaimer) {

		disclaimer.classList.remove(Classes.hidden);
		$Elements.body.addClass(Classes.disclaimer);

		$(disclaimer).on('close.bs.alert', function() {
			setDisclaimerAccepted();
			$Elements.body.removeClass(Classes.disclaimer);
		});
	}
}

// ---------- Button ScrollTo Content----------

$(".scroll-to-content").click(function(e) {
	e.preventDefault();

	const totalHeight = $("section.header-section").innerHeight();

	$("html, body").stop().animate(
		{
			scrollTop: totalHeight,
		},
		1000
	);

});



