import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import { $Elements, Durations, Events } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	$('.content-section .section-carousel, .content-section .column-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Slideshow ----------

	$('.wine-section .section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {
			const $this = $(this);
			const slideCount = $this.find('.swiper-slide').length;

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: false,
				centeredSlides: true,
				initialSlide: 1,
				breakpoints: {
					300: {
						slidesPerView: 1.9,
						spaceBetween: 0,
						allowTouchMove: true,
					},
					1024: {
						spaceBetween: 0,
						slidesPerView: 3,
						allowTouchMove: false,

					},
				},
			});
		}
	});
});
